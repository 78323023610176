<template>
  <div class="knowledge">
    <div class="knowledge-wrapper">
      <div class="zlxc-container" style="overflow: visible">
        <div class="bread-crumbs">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>学驾服务</el-breadcrumb-item>
            <el-breadcrumb-item>{{curAtom == 'drive' ? '学车须知' : '驾驶常识'}}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="cutover">
          <div
            :class="['atom', { action: curAtom === 'drive' }]"
            @click="tapAtom('drive')"
          >
            <p class="atom-title">学车须知</p>
            <p class="atom-footer">
              <img
                class="img"
                src="../../assets/zlxcimgs/second_icon_xuanzhong@2x.png"
              />
            </p>
          </div>
          <div
            :class="['atom', { action: curAtom === 'pilot' }]"
            @click="tapAtom('pilot')"
          >
            <p class="atom-title">驾驶常识</p>
            <p class="atom-footer">
              <img
                class="img"
                src="../../assets/zlxcimgs/second_icon_xuanzhong@2x.png"
              />
            </p>
          </div>
        </div>
        <div class="download-concrete">
          <div class="list-content">
            <div class="content-title">
              <p class="caption">标题</p>
              <p class="time">发布时间</p>
            </div>
            <ul class="list-ul">
                <li class="list-li" v-for="item in dataSheetList" :key="item.id" @click="tapJumpDetail(item.id)">
                  <p class="li-caption">{{item.title}}</p>
                  <p class="li-time">{{item.createTime}}</p>
                </li>
            </ul>
          </div>
        </div>
        <div class="newest-page">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="pageObj.total"
            :current-page="pageObj.pageIndex"
            @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryEssayList } from '@/api/herbalMedicine'
import { formatTime } from '@/utils/common.js'

export default {
  name: "DriveKnowledge",
  data() {
    return {
      // 当前Atom栏目
      curAtom: "drive", // drive/pilot
      // 分页信息
      pageObj: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
      // 列表
      dataSheetList: [],
    };
  },
  computed: {},
  created() {
    this.curAtom = this.$route.query.atomtype
    // 列表
    this.getQueryEssayList();
  },
  methods: {
    tapAtom(atom) {
        if (atom === this.curAtom) return
        this.curAtom = atom
        this.pageObj.pageIndex = 1
        this.getQueryEssayList()
    },
    // 列表 type 1: 学车须知 2：驾驶常识
    getQueryEssayList() {
      queryEssayList({
        pageIndex: this.pageObj.pageIndex,
        pageSize: this.pageObj.pageSize,
        type: this.curAtom == 'drive' ? 1 : 2,
      }).then((res) => {
        if (res.data.length > 0) {
          res.data.forEach((ele) => {
            ele.createTime = formatTime(ele.createTime);
          });
          this.dataSheetList = res.data;
          this.pageObj.total = res.count;
        }
      });
    },
    // 当前currentPage 改变时会触发
    handleCurrentChange(curPage) {
      this.pageObj.pageIndex = curPage;
      this.getQueryEssayList();
    },
    // 跳转详情页
    tapJumpDetail(id) {
      this.$router.push({
        name: "Details",
        query: {
          id,
          type: "knowledge",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
